import * as LabelPrimitive from '@radix-ui/react-label'
import * as React from 'react'

import { cn } from '#app/utils/misc.tsx'

interface CheckboxProps
	extends React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> {
	disabled?: boolean
}

const Label = React.forwardRef<
	React.ComponentRef<typeof LabelPrimitive.Root>,
	CheckboxProps
>(({ className, disabled, ...props }, ref) => (
	<LabelPrimitive.Root
		ref={ref}
		className={cn(
			// base
			'text-sm leading-none',
			// text color
			'text-neutral-900 dark:text-neutral-50',
			// disabled
			{
				'text-neutral-400 dark:text-neutral-600': disabled,
			},
			className,
		)}
		aria-disabled={disabled}
		{...props}
	>
		{props.children}{' '}
		{props['aria-required'] ? <span className="text-red-600">*</span> : null}
	</LabelPrimitive.Root>
))
Label.displayName = LabelPrimitive.Root.displayName

export { Label }
